export const facebook_logo=require("./Assests/facebook1.png");
export const google_logo=require("./Assests/google1.png");
export const email_logo=require("./Assests/email.png");
export const logo=require('./Assests/logo.png')
export const profile_img=require('./Assests/profile_img.png')
export const royal_logo=require('./Assests/login_img.jpg')
export const facebook_item=require('./Assests/facebook_item.png')
export const insta=require('./Assests/image_Insta.png')
export const linkdin =require('./Assests/linkdin.png')
export const gmail =require('./Assests/Email.png')
export const term_condition = require('./Assests/term-condition.webp');
export const slider1 = require('./Assests/sliderimg1.webp');
export const slider2 = require('./Assests/sliderimg2.webp');
export const slider3 = require('./Assests/sliderimg3.webp');
export const slider4 = require('./Assests/sliderimg4.webp');
export const slider5 = require('./Assests/sliderimg5.webp');
export const slider6 = require('./Assests/sliderimg6.webp');
export const slider7 = require('./Assests/sliderimg7.webp');
export const thankyouimage = require('./Assests/thankyou.jpg');
export const warningimage = require('./Assests/warning.jpg');
export const PaymentFailed = require('./Assests/paymentFailed.png');