import React from 'react'
import Slider from './Slider'
const LandingPage = () => {
  return (
      <div style={{
        minHeight: "calc(100vh - 180px)",

    }}>
      <Slider/>
    </div>
  )
}

export default LandingPage;